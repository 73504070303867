<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="dispatch_date" class="col-form-label col-form-label-sm">Dispatch Date <span class="custom-required">*</span></label>
              <datepicker :format="customDispatchDateFormat" v-model="delivery_challan.dispatch_date" v-validate="{ rules: { required:  true } }" :class="errors.has('dispatch_date') ? 'error-border-color' : ''"  id="dispatch_date"  name="dispatch_date" placeholder="Select dispatch date" ></datepicker>
            </div>
            <div class="col-md-6">
              <label for="challan_type" class="col-form-label col-form-label-sm">Challan Type <span class="custom-required">*</span></label>
              <select ref="challan_type" v-model="delivery_challan.challan_type" v-validate="{ rules: { required:  true } }" id="challan_type" name="challan type" class="form-control form-control-sm">
                <option selected disabled :value="''">Select challan type</option>
                <option value="1">Internal</option>
                <option value="2">External</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="delivery_from" class="col-form-label col-form-label-sm">Delivery From <span class="custom-required">*</span></label>
              <v-select v-model="delivery_challan.delivery_from" :class="errors.has('delivery_from') ? 'error-border-color' : ''" name="delivery_from" id="delivery_from" v-validate="'required'" label="name" :options="hubs" placeholder="Select delivery from"></v-select>
            </div>
            <div class="col-md-6" v-if="delivery_challan.challan_type === '1'">
              <label for="delivery_to" class="col-form-label col-form-label-sm">Delivery To <span class="custom-required">*</span></label>
              <v-select v-model="delivery_challan.delivery_to"  v-validate="'required_if:challan_type,1'" :class="errors.has('delivery_to') ? 'error-border-color' : ''" name="delivery_to" id="delivery_to" label="name" :options="hubs" placeholder="Select delivery to"></v-select>
            </div>
            <div class="col-md-6" v-if="delivery_challan.challan_type === '2'">
              <label for="receiver" class="col-form-label col-form-label-sm">Receiver`s Detail <span class="custom-required">*</span></label>
              <div class="form-row">
                <div class="col-md-11">
                  <v-select v-model="delivery_challan.receiver"  v-validate="'required_if:challan_type,2'" :class="errors.has('receiver') ? 'error-border-color' : ''" name="receiver" id="receiver" label="name" :options="employees" placeholder="Select receiver"></v-select>
                </div>
                <div class="col-md-1">
                  <span>
                      <a class="btn btn-success" href="javascript: void(0);" v-b-modal.external-add-employee-modal @click="$bvModal.show('external-add-employee-modal'), resetForm()"><i class="fa fa-plus"/></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="sender" class="col-form-label col-form-label-sm">Sender`s Detail <span class="custom-required">*</span></label>
              <div class="form-row">
                <div class="col-md-11">
                  <v-select v-model="delivery_challan.sender" :class="errors.has('sender') ? 'error-border-color' : ''" name="sender" id="sender" v-validate="'required'" label="name" :options="employees" placeholder="Select sender"></v-select>
                </div>
                <div class="col-md-1">
                  <span>
                      <a class="btn btn-success" href="javascript: void(0);" v-b-modal.add-employee-modal @click="$bvModal.show('add-employee-modal'), resetForm()"><i class="fa fa-plus"/></a>
                  </span>
                </div>
                <h6 v-if="delivery_challan.sender" style="margin-top: 3px; margin-left: 3px">
                  <b>Sender Name:</b> {{ senderInfo.name }} <br>
                  <b>Designation:</b> {{ senderInfo.designation }} <br>
                  <b>Department:</b> {{ senderInfo.department.department_name }} <br>
                  <b>Phone No:</b> {{ senderInfo.phone_no }}
                </h6>
              </div>
            </div>
            <div class="col-md-6" v-if="delivery_challan.challan_type === '2'">
              <h6 v-if="delivery_challan.receiver" style="margin-top: 3px; margin-left: 3px">
                <b>Receiver Name:</b> {{ receiverInfo.name }} <br>
                <b>Organization:</b> {{ receiverInfo.organization }} <br>
                <b>Phone No:</b> {{ receiverInfo.phone_no }}
              </h6>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6" v-if="delivery_challan.challan_type === '1'">
              <label for="recipient_name" class="col-form-label col-form-label-sm">Recipient Name <span class="custom-required">*</span></label>
              <input type="text" v-model="delivery_challan.recipient_name" v-validate="{ rules: { required: delivery_challan.challan_type == 1 ? true : false}, max: 255 }"  name="recipient_name" id="recipient_name" class="form-control form-control-sm" placeholder="Recipient name">
            </div>
            <div class="col-md-6" v-if="delivery_challan.challan_type === '1'">
              <label for="recipient_phone_no" class="col-form-label col-form-label-sm">Recipient Phone No. <span class="custom-required">*</span></label>
              <input type="text" v-model="delivery_challan.recipient_phone_no" v-validate="{ rules: { required: delivery_challan.challan_type == 1 ? true : false}, max: 11, regex: /^[0-9]*$/ }" name="recipient_phone_no" id="receiver_phone_no" class="form-control form-control-sm" placeholder="Recipient phone no.">
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-2">
              <button class="btn btn-sm btn-primary pull-right" @click="addRow">
                <i class="fa fa-plus mr-1"></i>Add Items
              </button>
            </div>
          </div>
          <table class="table table-sm table-bordered text-center">
            <thead>
            <tr>
              <th>Sl No</th>
              <th>Item Description</th>
              <th>Item Unit</th>
              <th style="width: 10%">Qty</th>
              <th>Remarks</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(delivery_challan_item, index) in delivery_challan_items' :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <input type="text" v-model="delivery_challan_item.challan_item"  name="challan_item" id="challan_item" class="form-control form-control-sm" placeholder="Item name">
              </td>
              <td>
                <input type="text" v-model="delivery_challan_item.unit"  v-validate="{ rules: { required: delivery_challan_item.challan_item ? true : false}, max: 5 }" name="unit" id="unit" class="form-control form-control-sm" placeholder="Item unit">
              </td>
              <td style="width: 10%">
                <input type="text" v-model="delivery_challan_item.transfer_qty" v-validate="{ rules: { required: delivery_challan_item.challan_item ? true : false}, max: 5, regex: /^[1-9]*$/ }"  name="transfer_qty" id="transfer_qty" class="form-control form-control-sm">
              </td>
              <td>
                <input type="text" v-model="delivery_challan_item.remarks"  name="remarks" id="remarks" class="form-control form-control-sm">
              </td>
              <td>
                <button class="btn btn-sm btn-danger" @click="deleteRow(index)" >
                  <i class="fa fa-remove mr-1"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <h5 class="text-center text-secondary" v-if="delivery_challan_items.length === 0">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-12">
              <label for="note" class="col-form-label col-form-label-sm">Note</label>
              <textarea class="form-control form-control-sm" v-model="delivery_challan.note" name="note" id="note"></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <label for="delivery_challan_picture" class="col-form-label col-form-label-sm">Challan Picture <span class="custom-required">*</span></label>
              <input type="file" ref="challanPicture" v-validate="'required'" id="delivery_challan_picture" name="delivery_challan_picture" @change="getChallanPicture()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="margin-bottom: 8px !important;">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-6">
              <label for="bearer_name" class="col-form-label col-form-label-sm">Bearer Name</label>
              <input type="text" class="form-control form-control-sm" v-model="delivery_challan.bearer_name" v-validate="{ rules: { required: delivery_challan.challan_type == 1 ? true : false}, max: 255 }"  name="bearer_name" id="bearer_name" placeholder="Enter bearer name" />
            </div>
            <div class="col-md-6">
              <label for="bearer_phone_no" class="col-form-label col-form-label-sm">Bearer Phone No.</label>
              <input type="text" class="form-control form-control-sm" v-model="delivery_challan.bearer_phone_no" v-validate="{ rules: { required: delivery_challan.challan_type == 1 ? true : false}, max: 11, regex: /^[0-9]*$/ }" name="bearer_phone_no" id="bearer_phone_no" placeholder="Enter bearer phone no" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <a-button v-if="delivery_challan_items.some(e => (e.challan_item !== '' && e.challan_item != null))" class="btn btn-sm btn-success float-left" :loading="submitLoading"  @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Create Delivery Challan</a-button>
        </div>
      </div>
    </div>
    <external-employee-add v-model="employees"></external-employee-add>
    <employee-add v-model="employees"></employee-add>
    <challan-item-add v-model="challan_items"></challan-item-add>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import externalEmployeeAdd from '@/views/deliveryChallan/partial/externalEmployeeAdd'
import employeeAdd from '@/views/deliveryChallan/partial/employeeAdd'
import challanItemAdd from '@/views/deliveryChallan/partial/challanItemAdd'
import { mapGetters } from 'vuex'

export default {
  name: 'create',
  components: { Empty, employeeAdd, Datepicker, challanItemAdd, externalEmployeeAdd },
  data() {
    return {
      hubs: [],
      employees: [],
      submitLoading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      challan_items: [],
      delivery_challan: {
        dispatch_date: '',
        challan_type: '',
        delivery_from: '',
        delivery_to: '',
        sender: '',
        receiver: '',
        note: '',
        bearer_name: '',
        bearer_phone_no: '',
        recipient_name: '',
        recipient_phone_no: '',
      },
      delivery_challan_picture: '',
      delivery_challan_items: [],
      validation_errors: {},
      show: false,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    senderInfo() {
      return this.delivery_challan.sender ? this.employees.find(element => {
        if (element.id === this.delivery_challan.sender.id) {
          return element
        }
        return null
      }) : ''
    },
    receiverInfo() {
      return this.delivery_challan.receiver ? this.employees.find(element => {
        if (element.id === this.delivery_challan.receiver.id) {
          return element
        }
        return null
      }) : ''
    },
  },
  mounted() {
    this.getCodes()
    if (this.user.role.includes('delivery_challan_user')) {
      this.delivery_challan.challan_type = 2
    }
  },
  methods: {
    customDispatchDateFormat(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    getCodes() {
      apiClient.get('/api/delivery-challan/codes')
        .then(response => {
          this.hubs = response.data.hubs
          this.employees = response.data.employees
          this.delivery_challan.delivery_from = response.data.delivery_from
        })
        .catch(error => {
          console.log(error)
        })
    },
    addRow: function () {
      this.delivery_challan_items.push({
        challan_item: '',
        unit: '',
        transfer_qty: '',
        remarks: '',
      })
    },
    deleteRow(index) {
      this.delivery_challan_items.splice(index, 1)
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          if (this.delivery_challan.delivery_from.id === this.delivery_challan.delivery_to.id) {
            this.$notification.error({
              message: 'Delivery from and delivery to can not be same',
            })
            return false
          }
          const deliveryChallanItems = this.delivery_challan_items.filter(element => {
            if (element.challan_item !== '' && element.challan_item != null) {
              return true
            }
            return false
          })
          // this.delivery_challan.delivery_challan_items = deliveryChallanItems
          this.submitLoading = true
          const formData = new FormData()
          formData.append('dispatch_date', JSON.stringify(this.delivery_challan.dispatch_date))
          formData.append('challan_type', this.delivery_challan.challan_type)
          formData.append('delivery_from', JSON.stringify(this.delivery_challan.delivery_from))
          formData.append('delivery_to', JSON.stringify(this.delivery_challan.delivery_to))
          formData.append('sender', JSON.stringify(this.delivery_challan.sender))
          formData.append('receiver', JSON.stringify(this.delivery_challan.receiver))
          formData.append('note', this.delivery_challan.note)
          formData.append('bearer_name', this.delivery_challan.bearer_name)
          formData.append('bearer_phone_no', this.delivery_challan.bearer_phone_no)
          formData.append('recipient_name', this.delivery_challan.recipient_name)
          formData.append('recipient_phone_no', this.delivery_challan.recipient_phone_no)
          formData.append('delivery_challan_picture', this.delivery_challan_picture)
          formData.append('delivery_challan_items', JSON.stringify(deliveryChallanItems))
          apiClient.post('/api/delivery-challan/store', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.$router.push({ name: 'senderChallanList' })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.submitLoading = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    getChallanPicture() {
      this.delivery_challan_picture = this.$refs.challanPicture.files[0]
      if (this.delivery_challan_picture.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.delivery_challan_picture = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    resetForm() {
      //
    },
  },
}
</script>

<style scoped>
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
</style>
